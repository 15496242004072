.navBarComponent {
  height: 60px;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: 0.5s;
  background-color: rgba(7, 102, 102, 0.808);
  display: flex;
  align-items: center;
}

.navBarContainer {
  /* background-color: yellow; */
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; 
  font-family: var(--prim-font);
}

.navNameContainer {
  display: flex;
}

.firstName {
  color: #FFFFFF;
}

.lastName {
  color: var(--tert-txt-color);
}

.firstName, .lastName {
  font-size: 20px;
  margin-right: 5px;

}

.firstNameEng, .lastNameEng {
  font-family: var(--heading-font);
}

.firstNameTh, .lastNameTh {
  font-family: var(--thai-font);
}

.navName:hover {
  cursor: pointer;
  user-select: none;
}

.navMenu {
  /* background-color: green; */
  display: flex;
  gap: 20px;
  margin-right: 20px;
}

.navBtn {
  font-family: var(--heading-font);
  font-size: 20px;

  background-color: transparent;
  border: none;
  color: #FFFFFF;
  text-decoration: underline 0.15em rgba(255, 255, 255, 0);
  transition: text-decoration-color 300ms;
}

.navBtn:hover {
  text-decoration-color: var(--dm-sec-txt-color);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .navBarComponent {
    display: none;
  }
}