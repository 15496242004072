.about-component {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-container {
  /* border: 5px solid #ffff00; */

  position: relative;
  height: 80%;
  width: 40%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.highlight {
  background-color: transparent;
}

.nameMark {
  cursor: pointer;
}

h2 {
  font-family: var(--prim-font);
}

p {
  font-family: var(--prim-font);
  font-size: 20px;
}

/* Laptop Resolution */
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .about-container {
    width: 40%;
  }

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 15px;
  }
}
