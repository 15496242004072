@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,500;1,500&display=swap");

:root {
  /* My chosen color pallete */
  --prim-bg-color: #aad4f3;
  --sec-bg-color: #86b9df;
  --tert-bg-color: #89b2d1;
  --quat-bg-color: #175d8d;
  --quin-bg-color: #104265;

  --prim-txt-color: #89b2d1;
  --sec-txt-color: #175d8d;
  --tert-txt-color: #104265;
  --quat-txt-color: #ffffff;

  --thai-font: "Prompt", sans-serif;
  --prim-font: "JetBrains Mono", serif;
  --std-bg-color: #ffffff;
}

h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h2 {
  margin-top: 0px;
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

.header-txt {
  /* background-color: blue; */
  text-align: center;
  height: auto;
  font-size: 50px;
  font-weight: bold;
  font-family: var(--prim-font);
  color: Black;
}

.sub-txt {
  /* background-color: green; */
  text-align: center;
  height: auto;
  font-size: 20px;
  font-family: var(--prim-font);
  font-weight: 300;
  color: Black;
}

/* Laptop Resolution */
@media screen and (max-width: 1024px) {
  .header-txt {
    font-size: 40px;
  }

  .sub-txt {
    font-size: 18px;
  }
}
