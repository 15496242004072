.first-page-component {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 100%;
  -webkit-text-size-adjust: 100%;
}

.name-section {
  width: 100%;

  /* background-color: yellow; */
  display: flex;
  align-items: center;
  padding-left: 150px;

  font-size: 7em;
  font-family: var(--prim-font);
  font-weight: 1000;
  -webkit-text-size-adjust: 100%;
}

.desc-section {
  /* background: blue; */
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 150px;
  font-size: 5em;
  font-family: var(--prim-font);
  -webkit-text-size-adjust: 100%;
}

/* Laptop Resolution */
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .name-section {
    font-size: 4em;
  }

  .desc-section {
    font-size: 3em;
  }
}
