.exp-desc-component {
  /* background-color: blue; */
  height: auto;
  width: 80%;
  margin-left: auto;
  margin-right: 0;

  position: relative;
  transition: 0.5s;
}

.exp-desc-header {
  /* background-color: red; */

  width: 100%;
  height: auto;
  margin-bottom: 3%;
}

.exp-desc-headerSec1 {
  /* background-color: green; */

  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;

  font-family: var(--prim-font);
}

.exp-desc-title {
  font-size: 40px;
  margin-left: auto;
  padding-right: 1%;
}

.expDescCompany {
  font-weight: 1000;
  transition: text-decoration-color 300ms;
  font-size: 40px;
  color: Black;
  text-decoration: underline 0.15em var(--std-bg-color);
}

.expDescCompany:hover {
  cursor: pointer;
  text-decoration-color: var(--quat-bg-color);
}

.exp-desc-headerSec2 {
  /* background-color: red; */

  width: 100%;
  display: flex;

  font-family: var(--prim-font);
  font-size: 25px;
}

.exp-desc-location {
  margin-left: auto;
  padding-right: 1%;
}

.exp-desc-body {
  /* background-color: yellow; */
  font-size: 22px;
  width: 100%;
  height: auto;
}

.exp-desc-unordered-list {
  /* background-color: red; */

  padding-left: 30px;
}

.exp-desc-list {
  font-family: var(--prim-font);
  padding-left: 10px;
  padding-bottom: 40px;
}

/* Laptop Resolution */
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .expDescCompany {
    font-size: 24px;
  }

  .exp-desc-title {
    font-size: 24px;
  }

  .exp-desc-headerSec2 {
    font-size: 15px;
  }

  .exp-desc-body {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .exp-desc-component {
    /* background-color: blue; */
    height: auto;
    width: 100%;

    position: relative;
    transition: 0.5s;
  }

  .exp-desc-header {
    /* background-color: red; */

    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .exp-desc-headerSec1 {
    /* background-color: green; */

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 0px;
    padding-bottom: 15px;

    font-family: var(--prim-font);
  }

  .exp-desc-title {
    font-size: 20px;
    margin-left: 0;
    padding-right: 0;
  }

  .expDescCompany {
    font-weight: 1000;
    transition: text-decoration-color 300ms;
    font-size: 30px;
    color: Black;
    text-decoration: underline 0.15em #f8f4ee;
  }

  .expDescCompany:hover {
    cursor: pointer;
    text-decoration-color: var(--quat-bg-color);
  }

  .exp-desc-headerSec2 {
    /* background-color: red; */

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    font-family: var(--prim-font);
    font-size: 15px;
  }

  .exp-desc-location {
    margin-left: 0;
    padding-right: 0;
  }

  .exp-desc-body {
    /* background-color: yellow; */
    font-size: 13px;
    width: 100%;
    height: auto;
  }

  .exp-desc-unordered-list {
    /* background-color: red; */

    padding-left: 15px;
  }

  .exp-desc-list {
    font-family: var(--prim-font);

    /* font-weight: 600; */
    padding-left: 10px;
    padding-bottom: 20px;
  }
}
