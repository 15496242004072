.exp-component {
  /* background-color: red; */
  height: 100vh;
  width: 100vw;
  transition: 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exp-container {
  /* border: 5px solid #ffff00; */

  position: relative;
  padding: 10px;
  height: 90%;
  width: 80%;
}


.exp-sub {
  margin-bottom: 40px;
}

.exp-body {
  /* background-color: red; */
  width: 100%;
  height: 75%;
  display: flex;
}

.exp-nav {
  /* background-color: green; */
  height: 100%;
  width: 15%;
}

.exp-nav-btn {
  background-color: transparent;

  width: 100%;
  height: 12%;

  font-family: var(--prim-font);
  font-weight: 600;
  font-size: 25px;
  text-align: left;
  color: Black;

  cursor: pointer;
  border: none;

  border-right: 2px solid rgba(10, 139, 139, 0.1);
}

.exp-nav-btn-active {
  /* background-color: green; */

  color: Black;
  background-color: rgba(8, 131, 131, 0.1);
  border-right: 2px solid #175d8d;
}

/* Laptop Resolution */
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .exp-nav-btn {
    font-size: 18px;
  }

  .exp-nav {
    width: 17%;
  }

  .exp-nav-btn {
    height: 14%;
  }
}
