.proj-component {
  /* background-color: red; */
  height: auto;
  width: 100vw;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proj-container {
  /* border: 5px solid #ffff00; */
  position: relative;
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.proj-body {
  /* background-color: blue; */

  align-items: center;
  justify-content: center;

  height: auto;
  position: relative;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.proj-body:hover > * {
  opacity: 0.3;
}

.proj-body {
  pointer-events: none;
}

.proj-body > * {
  pointer-events: auto;
}

.proj-body:hover > *:hover {
  opacity: 1;
  background-color: rgba(8, 131, 131, 0.1);
}
